@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans+Thai+Looped');

* {
  font-family: 'IBM Plex Sans Thai Looped', cursive;
}

.heading--main {
    color: #58595B;
    font-weight: 700;
    font-size: 1.5rem;
  }
  
  .primary-color {
    color: #FCB854;
  }
  
  .main-color {
    color: #58595B;
  }
  
  .mywallet-card {
    padding: 5px;
    min-height: 25vh;
    height: max-content;
    background-color: #FFFFFF;
    /* background: linear-gradient(145.17deg, #FFDB5E 17.17%, #FAAE50 88.61%); */
    border-radius: 16px;
    color: #B58C3E;
}
  
  .mywallet-card__content {
    width: 100%;
    padding: 0.5rem;
  }
  
  .mywallet-card__dtgo-logo { 
    height: 3.2rem;
    margin-right: 0.3rem;
  }

  .vl {
    border-left: 1.5px solid #B58C3E;
    margin-left: 1.3rem;
    margin-right: 1rem;
    height: 7vh;
  }
  
  .mywallet-card__infinyx-logo {
    height: 1.5rem;
  }
  
  .info-dtgo-logo {
    height: 3.5rem;
  }
  
  .mywallet-card__normal-text {
    font-size: 1.05rem;
    line-height: 1.25;
  }
  
  .mywallet-card__name-text {
    font-size: 0.85rem;
    line-height: 1.25;
  }

  .mywallet-card__email-text {
    font-size: 0.85rem;
    color: #6B5325;
    line-height: 1.25;
  }

  .mywallet__card_plan {
    background: #F9F9FB;
    border: 1px solid #EEEEEF;
    border-radius: 16px;
  }
  
  .mywallet-card__large-text {
    background: -webkit-linear-gradient(left, #FFCE7A, #FFAE00);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .yellow-gradient {
    background: -webkit-linear-gradient(left, #FFCE7A, #FFAE00);
  }

  .mywallet-card-text {
    background: -webkit-linear-gradient(left, #FFCE7A, #FFAE00);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 2rem;
  }
  
  .mywallet-card__line {
    height: 1.5px;
    background: #DED7C8;
    border: none;
    color: #DED7C8;  /* old IE */
    background-color: #DED7C8; 
    opacity: 1;
    margin: 0.5rem 0;
  }
  
  .mywallet__detail {
    display: inline-flex;
    width: 100%;
    font-size: 0.8rem;
  }
  
  .mywallet__balance.child {
    display: inline-block;
    align-self: flex-end;
  }
  
  .mywallet__icon {
    margin-left: 10px;
    margin-right: 10px;
  }

  .mywallet-card__topup-text {
    font-size: 1rem;
    color: #8C6C30;
    line-height: 1.25;
    font-weight: 600;
    margin: 4px 0;
  }

  .mywallet-card__topup-button{
    padding: 4px;
    border-radius: 12px;
    border: 1px solid #E8B34F;
    background-color: #FFF9EE;
    cursor: pointer;
  }
  .mywallet-card__grid {

      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 0px;
  }
   .mywallet-card__grid-left { grid-area: 1 / 1 / 4 / 3; }
    .mywallet-card__grid-right { 
      grid-area: 1 / 4 / 2 / 5; 
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
  .pay-card {
    padding: 10px;
    min-height: 16vh;
    height: max-content;
    background: linear-gradient(145.17deg, #FFDB5E 17.17%, #FAAE50 88.61%);
    border-radius: 16px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    color: white;
  }
  
  .pay-heading {
    justify-content: center;
    padding: 10px;
    width: 100%;
    background: #FFC557;
    color: white;
  }
  
  .pay-card__large-text {
    font-size: 1.5rem;
  }
  
  .pay-qr {
    height: 35vh;
  }
  .topup-qr {
    height: 14.5rem;
  }
  
  .info-view-btn {
    align-items: center;
    padding: 4px 12px;
    height: 26px;
    /* background: #FFF9CB;
    border-radius: 16px;
    color: #F08944; */
    color: #E8B34F;
    background: #FFF9EE;
    border: 1px solid #E8B34F;
    border-radius: 16px;
    border: 0;
    float: right;
    margin-top:0.8rem;
  }
  
  .info__detail {
    display: inline-flex;
    width: 100%;
    font-size: 1rem;
    float: left;
  
    border-radius: 12px;
    border: 1px solid;
    min-height: 5vh;
    border-style: solid;
    border-color: #F08944;
    padding: 1rem 1rem 1rem 0rem;
  }
  
  .info__logo {
    margin-right: 1rem;
    margin-left: 1rem;
  }
  
  .info__detail-content {
    width: inherit;
  }
  
  .info__detail-content .child{
    float: left;
  }
  
  .history__heading {
    color: #58595B;
  }
  
  .history__detail {
    display: inline-flex;
    width: 100%;
    font-size: 1rem;
  }
  
  .history__amount {
    color: #58595B;
  }
  
  .history__timestamp {
    color: #9C9C9C;
  }
  
  .deeplink-menu {
    overflow: hidden;
    position: fixed;
    bottom: 6rem;
    height: 3rem;
    width: 100%;
    background-color:red;
  }
  
  /* Place the navbar at the bottom of the page, and make it stick */
  .navbar {
    background-color: #F9F9FB;
    overflow: hidden;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 6rem;
    padding: 0.5rem;
  }
  
  /* Style the links inside the navigation bar */
  .navbar a {
    float: left;
    display: block;
    color: #58595B;
    text-align: center;
    padding: 0.5rem 0.5rem;
    text-decoration: none;
    font-size: 1rem;
    width: calc(100% / 4);
  }
  
  /* Change the color of links on hover */
  /* .navbar a:hover {
    background-color: #ddd;
    color: black;
  } */
  
  /* Add a color to the active/current link */
  
  /* background-color: #FFC557; */
  
  .navbar a.active {
    background: -webkit-linear-gradient(left, #FFCE7A, #FFAE00);
    color: white;
    text-align: center;
    border-radius: 12px;
  }

  .refresh-button {
    padding: 8px;
    border: 1px solid #B58C3E;
    border-radius: 12px;
    color: #B58C3E;
    background-color: white;
  }

  /* TOPUP */
  .topup_amount-box {
    margin: 16px;
    height:90vh;
    position: relative;
  }
  .topup_amount-header{
    color: #3E3F41;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.6000000238418579px;
    text-align: left;
  }


  .topup_amount-input-container  {
    display: flex;
    border-radius: 0.25rem;
    align-items: center; /* This will ensure that children are vertically centered */
    border: 1px solid #ccc;

    position: relative;
  }

  .topup-refresh-button {
    padding: 16px;
    border: 1px solid #B58C3E;
    border-radius: 12px;
    color: #B58C3E;
    background-color: white;
  }
  .topup-cancel-button {
    padding: 16px;
    border: 1px solid #58595B;
    border-radius: 12px;
    color: #58595B;
    background-color: white;
  }
  .topup-loader-overlay {

    background-color: #111;
    opacity: 0.8;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top:0;
    z-index:999;
  }
  .topup-loader-modal {
    display: flex;
    width: 5.1875rem;
    height: 5.25rem;
    padding: 1.125rem 0.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    opacity:0.9;
    background-color:  #000;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
    z-index:1000;
  }

  
  .symbol {
    margin: 0.5rem 0.9rem;
  }
  
  .divider {
    height: 100%;
    min-height: 2.5rem;
    width: 1px;
    background-color: #EBEBEB;
    margin-right: 0.5rem;
  }
  
  .number-input {
    flex-grow: 1;
    border: none;
    outline: none;
  }
  
  .cross-icon {
    cursor: pointer;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  
  .topup_amount-text{
    color:  #E8B34F;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.6px;
  }
  .topup_amount-button-container{
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
  }
  .topup_amount-button{
    display: flex;
    padding: 12px 12px 12px 6px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid var(--grey-grey-200, #B2B3B4);
  }

  .topup_next-button{
    display: block;
    border: 1px solid #E8B34F;
    background: linear-gradient(0deg, #FFC557, #FFC557), linear-gradient(0deg, #E8B34F, #E8B34F);
    width: 100%;
    height: 3em;
    border-radius: 12px;
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%); /* To center the button horizontally */
  }
  .topup_next-button:disabled{
    border: 1px solid #EBEBEB;
    background: var(--neutral-neu-300, #EBEBEB);
  }

  .topup_valid-icon{
    color: #E8B34F;
    height: 16px;
    vertical-align: middle;
    display: inline-block;
    margin-top:-2px;
    margin-right:4px;
  }

  .topup_invalid-text{
    color: #E46666;
  }

  .react-tabs {
    -webkit-tap-highlight-color: transparent;
    width: 100%;
  }
  
  .react-tabs__tab-list {
    margin: 0 0 10px;
    padding: 0;
    width: 100%;
  }
  
  .react-tabs__tab {
    display: inline-block;
    cursor: pointer;
    text-align: center;
    font-weight: 700;
    width: calc(100% / 2);
    padding: 15px;
    border-bottom: 2.5px solid #aaa;
    color: grey;
  }
  
  .react-tabs__tab--selected {
    border-bottom: 2.5px solid #B5A05E;
    color: #B5A05E;
  }